<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button
        :to="`/admin/configuraciones/estados/${stateId}/municipios/crear`"
      >
        Crear municipio
      </c-primary-button>
    </div>
    <c-card title="Municipios">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Municipio</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="city in cities" :key="city.id">
            <td>{{ city.name }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/configuraciones/estados/${stateId}/municipios/${city.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteCity(city.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { cities } from "@/services/states";
import { remove } from "@/services/cities";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      cities: [],
      stateId: this.$route.params.id,
    };
  },
  mounted() {
    this.getStateCities();
  },
  methods: {
    getStateCities() {
      cities(this.stateId)
        .then((response) => {
          this.cities = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los estados");
        });
    },
    deleteCity(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getStateCities();
      });
    },
  },
};
</script>
